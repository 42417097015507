import gql from 'graphql-tag'

import { responsiveImageFragment } from '../fragments/images.js'

import { seoFragment } from '../fragments/seo.js'

export const getBlogPage = gql`
  ${responsiveImageFragment}
  ${seoFragment}
  query GetBlogPage(
    $locale: SiteLocale
    $filter: ArticleModelFilter
    $first: IntType
    $skip: IntType
  ) {
    blogListing(locale: $locale) {
      title
      subtitle
      introText
      seo {
        ...SeoFragment
      }
      ctaType
      contactBannerBlock {
        id
        title
        videoUrl
        ctaLink
        ctaLabel
      }
    }
    allArticles(locale: $locale, filter: $filter, first: $first, skip: $skip) {
      id
      title(locale: $locale)
      slug(locale: $locale)
      inEvidenza
      tag {
        id
        slug
        title
      }
      image {
        id
        responsiveImage(imgixParams: { auto: format, q: "60", fit: crop }) {
          ...ResponsiveImageFragment
        }
      }
    }
    article(locale: $locale, filter: { inEvidenza: { eq: "true" } }) {
      id
      title(locale: $locale)
      slug(locale: $locale)
      inEvidenza
      tag {
        id
        slug
        title
      }
      image {
        id
        responsiveImage(imgixParams: { auto: format, q: "60", fit: crop }) {
          ...ResponsiveImageFragment
        }
      }
    }
    allBlogTags(locale: $locale) {
      title
      slug
      id
    }
    _allArticlesMeta(locale: $locale, filter: $filter) {
      count
    }
  }
`

export const getArticle = gql`
  ${responsiveImageFragment}
  ${seoFragment}
  query GetArticle(
    $locale: SiteLocale
    $filter: ArticleModelFilter
    $filterOthers: ArticleModelFilter
  ) {
    article(locale: $locale, filter: $filter) {
      _allSlugLocales(fallbackLocales: it) {
        value
        locale
      }
      seo {
        ...SeoFragment
      }
      id
      title
      slug
      titleFormatted
      tag {
        title
        slug
      }
      image {
        id
        responsiveImage(imgixParams: { auto: format, q: "60", fit: crop }) {
          ...ResponsiveImageFragment
        }
      }
      articoliCorrelati {
        id
        title
        slug
        inEvidenza
        tag {
          id
          slug
          title
        }
        image {
          id
          responsiveImage(imgixParams: { auto: format, q: "60", fit: crop }) {
            ...ResponsiveImageFragment
          }
        }
      }
      ctaType
      contactBannerBlock {
        id
        title
        videoUrl
        ctaLink
        ctaLabel
      }

      body {
        value
        links {
          ... on ContactPageRecord {
            id
            title
          }
          ... on ExpertiseRecord {
            id
            slug
            title
          }
          ... on ProjectRecord {
            id
            slug
            title
          }
          ... on TechnologyRecord {
            id
            slug
            title
          }
        }
        blocks {
          ... on MediaBlockRecord {
            id
            linkVideo
            fullWidth
            isVertical
            isAutoplay
            autoplayWithClick
            image {
              id
              responsiveImage(
                imgixParams: { auto: format, q: "60", fit: crop }
              ) {
                ...ResponsiveImageFragment
              }
            }
          }
          ... on MediaCarouselRecord {
            id
            isVertical
            slides {
              id
              linkVideo
              linkVideoPreview
              image {
                id
                responsiveImage(
                  imgixParams: { ar: "16:9", fit: crop, w: "2000", fm: webp }
                ) {
                  ...ResponsiveImageFragment
                }
              }
              portrait: image {
                id
                responsiveImage(
                  imgixParams: { ar: "4:5", fit: crop, w: "2000", fm: webp }
                ) {
                  ...ResponsiveImageFragment
                }
              }
            }
          }
          ... on QuoteBlockRecord {
            id
            quote
            author
          }
        }
      }
    }
    allArticles(locale: $locale, filter: $filterOthers, first: 6) {
      id
      title(locale: $locale)
      slug(locale: $locale)
      inEvidenza
      tag {
        id
        slug
        title
      }
      image {
        id
        responsiveImage(imgixParams: { auto: format, q: "60", fit: crop }) {
          ...ResponsiveImageFragment
        }
      }
    }
  }
`
