import gql from 'graphql-tag'

import { seoFragment } from '../fragments/seo.js'
import { responsiveImageFragment } from '../fragments/images.js'

export const getPrivacyPage = gql`
  ${seoFragment}
  query GetPrivacyPage($locale: SiteLocale) {
    privacyPolicy(locale: $locale) {
      title
      seo {
        ...SeoFragment
      }
      body
    }
  }
`

export const getContactsPage = gql`
  ${seoFragment}
  query GetContactsPage($locale: SiteLocale) {
    contactPage(locale: $locale) {
      seo {
        ...SeoFragment
      }
      title
      introText
      contactsList {
        text
      }
      formTitle
    }
  }
`

export const getContactsSplashPage = gql`
  ${seoFragment}
  query GetContactsSplashPage($locale: SiteLocale) {
    contactSplashPage(locale: $locale) {
      seo {
        ...SeoFragment
      }
      titleSplash
      splashLinks {
        id
        linkLabel
        linkUrl
      }
      splashFooterLeft
      splashFooterRight
    }
  }
`

export const getCareersPage = gql`
  ${seoFragment}
  ${responsiveImageFragment}
  query GetCareersPage($locale: SiteLocale) {
    careersPage(locale: $locale) {
      seo {
        ...SeoFragment
      }
      title
      introText
      openPositionsTitle
      bottomImage {
        id
        responsiveImage(
          imgixParams: { ar: "16:9", fit: crop, w: "2000", fm: webp }
        ) {
          ...ResponsiveImageFragment
        }
      }
      body {
        __typename
        ... on ImageVideoBlockRecord {
          id
          image {
            id
            responsiveImage(imgixParams: { fit: crop, w: "2000", fm: webp }) {
              ...ResponsiveImageFragment
            }
          }
          linkVideo
          linkVideoPreview
          smallHeight
        }
        ... on DoubleMediaRowRecord {
          id
          item {
            image {
              id
              responsiveImage(imgixParams: { fit: crop, w: "2000", fm: webp }) {
                ...ResponsiveImageFragment
              }
            }
            linkVideo
            linkVideoPreview
            smallHeight
          }
        }
        ... on TwoColumnsGridTextRecord {
          id
          text
          title
          titleAsSimpleText
        }
        ... on ServicesBlockRecord {
          id
          title
          texts {
            id
            title
            text
          }
        }
      }
    }
    clientiSection(locale: $locale) {
      title
      id
      imagesList {
        url
        alt
      }
    }
    allCareers(locale: $locale) {
      id
      title(locale: $locale)
      slug(locale: $locale)
    }
  }
`

export const getCareerPage = gql`
  ${seoFragment}
  query GetCareerPage($locale: SiteLocale, $slug: String) {
    career(locale: $locale, filter: { slug: { eq: $slug } }) {
    _allSlugLocales {
      locale
      value
    }
      seo {
        ...SeoFragment
      }
      title
      slug
      description
    }
    allCareers(locale: $locale, filter: { slug: { neq: $slug } }) {
      id
      title(locale: $locale)
      slug(locale: $locale)
    }
  }
`

export const getAreYouAnAgency = gql`
  ${seoFragment}
  ${responsiveImageFragment}
  query GetAreYouAnAgency($locale: SiteLocale) {
    areYouAnAgency(locale: $locale) {
      seo {
        ...SeoFragment
      }
      title
      introText
      whyUsTitle
      whyUsDescription
      startAProjectTitle
      startAProjectLink {
        id
        linkLabel
        linkUrl
      }
      body {
        __typename
        ... on ImageVideoBlockRecord {
          id
          image {
            id
            responsiveImage(imgixParams: { fit: crop, w: "2000", fm: webp }) {
              ...ResponsiveImageFragment
            }
          }
          linkVideo
          linkVideoPreview
          smallHeight
        }
        ... on DoubleMediaRowRecord {
          id
          item {
            image {
              id
              responsiveImage(imgixParams: { fit: crop, w: "2000", fm: webp }) {
                ...ResponsiveImageFragment
              }
            }
            linkVideo
            linkVideoPreview
            smallHeight
          }
        }
        ... on TwoColumnsGridTextRecord {
          id
          text
          title
          titleAsSimpleText
        }
        ... on BlockListInBackgroundcolorRecord {
          id
          title
          description
        }
      }
    }
  }
`

export const getContactBanner = gql`
  query GetContactBanner($locale: SiteLocale) {
    contactBanner(locale: $locale) {
      title
      ctaLabel
      ctaLink
      videoUrl
    }
    contactBanner2(locale: $locale) {
      title
      ctaLabel
      ctaLink
      videoUrl
    }
  }
`
