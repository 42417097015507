<template>
  <div class="nav-large__wrap flex is-justify-space-between">
    <!-- Logo -->
    <NuxtLink
      :to="localePath({ name: 'index' })"
      :external="external"
      class="nav-large__logo"
    >
      <img
        :src="isLight ? GlyphLogoWhite : GlyphLogo"
        class="nav-large__logo-glyph"
        height="30"
        :alt="runtimeConfig.public.siteName"
      />
      <div class="nav-large__logo-extend">
        <img
          ref="logo"
          height="30"
          :src="isLight ? CutLogoWhite : CutLogo"
          :alt="runtimeConfig.public.siteName"
        />
      </div>
      <img
        ref="logoDot"
        class="nav-large__logo-dot"
        height="30"
        :src="isLight ? DotLogoWhite : DotLogo"
        :alt="runtimeConfig.public.siteName"
      />
    </NuxtLink>

    <div class="nav-large__list__wrap" @mouseleave="mouseLeave">
      <ul ref="nav" class="nav-large__list">
        <li class="nav-large__list-main-link">
          <nuxt-link
            :to="localePath({ name: 'projects' })"
            class="text-s-1 link-underline-reverse-1"
            :external="external"
            :class="{ '--active': route.name && route.name.includes('projects') }"
            >{{ $t('menu.menu-nav.works') }}</nuxt-link
          >
        </li>
        <li
          @mouseenter="mouseEnter('expertise')"
          @mouseleave="mouseLeaveSubMenu"
          class="nav-large__list-main-link"
        >
          <nuxt-link
            :to="localePath({ name: 'expertise' })"
            class="text-s-1"
            :external="external"
            :class="{ '--active': route.name && route.name.includes('expertise') }"
            >{{ $t('menu.menu-nav.expertise') }}</nuxt-link
          >
          <div
            :class="{ '--is-open': openSubmenu && showSubmenu === 'expertise' }"
            class="nav-large__dropdown-menu-wrap"
          >
            <ul>
              <li
                v-for="link in props.submenu?.expertise"
                :key="link.route.params.slug"
              >
                <nuxt-link
                  :to="localePath(link.route)"
                  class="nav-large__dropdown-menu-link"
                  @click.prevent="mouseLeaveSubMenu"
                  :external="external"
                >
                  <span> {{ link.title }}</span>
                  <img :src="IconArrow" width="24" :alt="link.title" />
                </nuxt-link>
              </li>
            </ul>
          </div>
        </li>
        <li
          @mouseenter="mouseEnter('technology')"
          @mouseleave="mouseLeaveSubMenu"
          class="nav-large__list-main-link"
        >
          <nuxt-link
            :to="localePath({ name: 'technology' })"
            class="text-s-1"
            :external="external"
            :class="{ '--active': route.name && route.name.includes('technology') }"
            >{{ $t('menu.menu-nav.technology') }}</nuxt-link
          >
          <div
            :class="{
              '--is-open': openSubmenu && showSubmenu === 'technology',
            }"
            class="nav-large__dropdown-menu-wrap"
          >
            <ul>
              <li
                v-for="link in props.submenu?.technology"
                :key="link.route.params.slug"
              >
                <nuxt-link
                  :to="localePath(link.route)"
                  :external="external"
                  class="nav-large__dropdown-menu-link"
                  @click.prevent="mouseLeaveSubMenu"
                >
                  <span> {{ link.title }}</span>
                  <img :src="IconArrow" width="24" :alt="link.title" />
                </nuxt-link>
              </li>
            </ul>
          </div>
        </li>
        <li class="nav-large__list-main-link">
          <nuxt-link
            :to="localePath({ name: 'studio' })"
            class="text-s-1 link-underline-reverse-1"
            :external="external"
            :class="{ '--active': route.name && route.name.includes('studio') }"
            >{{ $t('menu.menu-nav.studio') }}</nuxt-link
          >
        </li>
        <li class="nav-large__list-main-link">
          <nuxt-link
            :to="localePath({ name: 'blog' })"
            class="text-s-1 link-underline-reverse-1"
            :class="{ '--active': route.name && route.name.includes('blog') }"
            :external="external"
            >{{ $t('menu.menu-nav.blog') }}</nuxt-link
          >
        </li>
        <li class="nav-large__list-main-link">
          <nuxt-link
            :to="localePath({ name: 'contacts' })"
            class="text-s-1 link-underline-reverse-1"
            :class="{ '--active': route.name && route.name.includes('contacts') }"
            :external="external"
            >{{ $t('menu.menu-nav.contacts') }}</nuxt-link
          >
        </li>
        <li></li>
          <li  class="nav-large__list-main-link is-text-uppercase" v-for="locale in locales.filter((code) => code.code !== current.code)"
        :key="locale.code">
           <!-- <nuxt-link
            class="text-s-1 link-underline-reverse-1"
          :to="switchLocalePath(locale.code)"
        >
          <span> {{ locale.code }}</span>
          
        </nuxt-link>-->
         <a
         @click.prevent.stop="setLocale(locale.code)"
         class="text-s-1 link-underline-reverse-1"
         :href="switchLocalePath(locale.code)"
        >
          <span> {{ locale.code }}</span>
          
        </a>
          </li>
      </ul>
      <!-- Button -->
      <button
        v-if="false"
        ref="menuButton"
        class="nav-large__button text-s-1"
        @click="isHover = true"
      >
        {{ $t('menu.menu-nav.menu') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavigationMenuLarge',
}
</script>

<script setup>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import GlyphLogo from '@/assets/img/ico/logo-glyph-b-bluemotion.svg'
import GlyphLogoWhite from '@/assets/img/ico/logo-glyph-b-bluemotion-white.svg'
import CutLogo from '@/assets/img/ico/logo-cut-bluemotion-no-dot.svg'
import CutLogoWhite from '@/assets/img/ico/logo-cut-bluemotion-no-dot-white.svg'
import DotLogo from '@/assets/img/ico/logo-cut-dot.svg'
import DotLogoWhite from '@/assets/img/ico/logo-cut-dot-white.svg'

import IconArrow from '@/assets/img/ico/ico-arrow-next-circle-blu.svg'

gsap.registerPlugin(ScrollTrigger)

const runtimeConfig = useRuntimeConfig()
const route = useRoute()

const lenis = inject('lenis')

const nav = ref(null)
const menuButton = ref(null)
const logo = ref(null)
const logoDot = ref(null)
const duration = 0.4

const isHover = ref(false)

const isNewPage = ref(true)

const showSubmenu = ref(null)
const openSubmenu = ref(false)

const switchLocalePath = useSwitchLocalePath()
const { locale, locales, setLocale } = useI18n()

const current = computed(() => {
  return locales.value.find(({ code }) => code === locale.value)
})

const props = defineProps({
  isLight: Boolean,
  submenu: Object,
  external: {
    type: Boolean,
    default: false,
  },
})

watch(
  () => isHover.value,
  (isHover) => {
    if (isHover) {
      // animateMenuIn().play()
    } else {
      if (lenis.value.scroll > 100 && !isNewPage.value) {
        // animateMenuOut().play()
      }
    }
  }
)

watch(
  () => route.path,
  () => {
    isNewPage.value = true
    //animateMenuOut().play()

    /*if (isHover.value) {
      isHover.value = true
    }*/
  }
)

onMounted(() => {
  isNewPage.value = false

  const tl = gsap.timeline({
    ease: 'power2.out',
    paused: true,
  })

  tl.to(logo.value, { x: '-115%' })
  tl.to(logoDot.value, { x: '-148px' }, '<')
  /* tl.to(
    nav.value,
    {
      x: '100%',
    },
    '<'
  )
  tl.to(menuButton.value, { x: '0%', autoAlpha: 1 }) */

  ScrollTrigger.create({
    id: 'scrollMenu',
    start: '100px top',
    onEnter: () => {
      if (!isHover.value) {
        tl.play()
      } else {
        animateMenuOut()
      }

      isNewPage.value = false
    },
    onLeaveBack: () => {
      if (isNewPage.value) {
        isNewPage.value = false
      } else {
        if (isHover.value) {
          tl.pause()
        } else {
          tl.reverse()
        }
      }
    },
  })
})

const animateMenuIn = () => {
  const tl = gsap.timeline({
    totalDuration: 0.6,
    ease: 'power2.out',
    paused: true,
  })

  tl.to(menuButton.value, {
    x: '100%',
    autoAlpha: 0,
    duration: 0.1,
  })
  tl.to(nav.value, { x: '0%' })

  return tl
}

const animateMenuOut = () => {
  const tl = gsap.timeline({
    totalDuration: 0.6,
    ease: 'power2.out',
    paused: true,
  })

  tl.to(nav.value, { x: '100%' })
  tl.to(menuButton.value, {
    x: '0%',
    autoAlpha: 1,
    duration: 0.1,
  })

  return tl
}

const mouseLeave = () => {
  isHover.value = false
}

const navigate = () => {
  isHover.value = false
}

const mouseEnter = (submenuCat) => {
  showSubmenu.value = submenuCat
  openSubmenu.value = true
}

const mouseLeaveSubMenu = () => {
  openSubmenu.value = false
  showSubmenu.value = null
}
</script>

<style lang="scss">
@import './style.scss';
</style>
