import * as dato from './dato.js'
import * as home from './home.js'
import * as projects from './projects.js'
import * as expertise from './expertise.js'
import * as technology from './technology.js'
import * as blog from './blog.js'
import * as corporates from './corporates.js'
import * as studio from './studio.js'
import * as navigation from './navigation.js'
import * as letsTalk from './lets-talk.js'
import * as sitemap from './sitemap.js'
import * as footer from './footer.js'
import * as landing from './landing.js'

export default {
  dato,
  home,
  projects,
  expertise,
  technology,
  blog,
  corporates,
  studio,
  navigation,
  letsTalk,
  sitemap,
  footer,
  landing,
}
