export default defineNuxtRouteMiddleware((to, from) => {
  // Lista delle chiavi da escludere
  const excludedKeys = ['action', 'categories', 'technology', 'industry', 'page']

  // Filtra le query della pagina corrente escludendo le chiavi specificate
  const filteredQuery = Object.fromEntries(
    Object.entries(from.query).filter(([key]) => !excludedKeys.includes(key))
  )

  // Combina `to.query` e le query filtrate di `from`
  const combinedQuery = {
    ...filteredQuery, // Query della pagina di origine
    ...to.query,          // Query della pagina di destinazione (ha la precedenza)
  }

  // Se ci sono modifiche nelle query, aggiorna l'URL
  if (Object.keys(combinedQuery).length && JSON.stringify(combinedQuery) !== JSON.stringify(to.query)) {
    return navigateTo({ ...to, query: combinedQuery })
  }
})
