import revive_payload_client_vuNAaAQmK9 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.1_@types+node@20.17.19_db0@0.2.4_eslint@8.57.1_ioredis@_1a57c3a5608bea12016aa052cfba7332/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ueZWeqVOw8 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.1_@types+node@20.17.19_db0@0.2.4_eslint@8.57.1_ioredis@_1a57c3a5608bea12016aa052cfba7332/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GP0sG2uEtx from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.1_@types+node@20.17.19_db0@0.2.4_eslint@8.57.1_ioredis@_1a57c3a5608bea12016aa052cfba7332/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_mQUEStzFVo from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_vite@5.4.14_@types+node@20.17.19_sass@1.85.1_ter_412d3314a8bdf3d8955d2f6aca9e81bf/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_Vh4yuQ7oAU from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.1_@types+node@20.17.19_db0@0.2.4_eslint@8.57.1_ioredis@_1a57c3a5608bea12016aa052cfba7332/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_0fWerFOd6e from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.1_@types+node@20.17.19_db0@0.2.4_eslint@8.57.1_ioredis@_1a57c3a5608bea12016aa052cfba7332/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_GM7qLE8xDF from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.1_@types+node@20.17.19_db0@0.2.4_eslint@8.57.1_ioredis@_1a57c3a5608bea12016aa052cfba7332/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_tXhoDUTbRt from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.1_@types+node@20.17.19_db0@0.2.4_eslint@8.57.1_ioredis@_1a57c3a5608bea12016aa052cfba7332/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_rumxPbp9RC from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.4.11_magicast@0.3.5_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_8ccKsLFhbu from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.1_@types+node@20.17.19_db0@0.2.4_eslint@8.57.1_ioredis@_1a57c3a5608bea12016aa052cfba7332/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_00PtOlZSXL from "/opt/build/repo/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.12.4_@parcel+watcher@2.5.1_@type_e3724a23e757fca66b25871cbd6aa140/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import pwa_icons_plugin_LnrhIJeMG7 from "/opt/build/repo/.nuxt/pwa-icons-plugin.ts";
import pwa_client_3D6oCM7Fsr from "/opt/build/repo/node_modules/.pnpm/@vite-pwa+nuxt@0.9.1_@vite-pwa+assets-generator@0.2.6_magicast@0.3.5_vite@5.4.14_@types_830b6b39fae1937ec717b4afe71e35d9/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_dZqRlTf5dh from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.8_magicast@0.3.5_rollup@4.34.8_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_lcDPsOIxdJ from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.34.8_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_jHonLTUQqo from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.34.8_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import animation_directives_client_BcJ9PYvc9H from "/opt/build/repo/plugins/animation-directives.client.js";
import apollo_IRWTEOPleP from "/opt/build/repo/plugins/apollo.js";
import sentry_client_GoGQuZo4Et from "/opt/build/repo/plugins/sentry.client.js";
import setup_directives_client_fwoBYyxQ4Q from "/opt/build/repo/plugins/setup-directives.client.js";
import tracking_client_fEUopUxxSj from "/opt/build/repo/plugins/tracking.client.js";
export default [
  revive_payload_client_vuNAaAQmK9,
  unhead_ueZWeqVOw8,
  router_GP0sG2uEtx,
  _0_siteConfig_mQUEStzFVo,
  payload_client_Vh4yuQ7oAU,
  navigation_repaint_client_0fWerFOd6e,
  check_outdated_build_client_GM7qLE8xDF,
  chunk_reload_client_tXhoDUTbRt,
  plugin_vue3_rumxPbp9RC,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8ccKsLFhbu,
  plugin_00PtOlZSXL,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_3D6oCM7Fsr,
  plugin_dZqRlTf5dh,
  switch_locale_path_ssr_lcDPsOIxdJ,
  i18n_jHonLTUQqo,
  animation_directives_client_BcJ9PYvc9H,
  apollo_IRWTEOPleP,
  sentry_client_GoGQuZo4Et,
  setup_directives_client_fwoBYyxQ4Q,
  tracking_client_fEUopUxxSj
]