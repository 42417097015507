<template>
  <nav class="nav-menu" :class="{ '--open': openOverlay }">
    <div class="row-2">
      <NavigationLarge
        class="nav-menu__large"
        :is-light="isLight"
        :submenu="{
          expertise: linksSubmenuExpertiseDesk,
          technology: linksSubmenuTechnologyDesk,
        }"
        v-if="matchMedia"
        :external="external"
      />
      <NavigationSmall
        class="nav-menu__small"
        @open-menu="toggleOverlay"
        :is-light="isLight"
        v-if="!matchMedia"
        :external="external"
      />
    </div>
    
    <!-- Overlay -->
    <WidgetOverlayFullScreen
      class="nav-menu__overlay hide-for-large"
      ref="overlay"
      :right="true"
    >
      <div class="nav-menu__overlay__wrap" data-lenis-prevent>
        <button class="nav-menu__overlay__close-btn" @click="toggleOverlay">
          <img
            src="@/assets/img/ico/ico-close-white.svg"
            ref="closeButton"
            alt="Close"
            class="nav-menu__overlay__close"
          />
        </button>
        <NavigationMenuLinks
          class="nav-menu__links --main"
          ref="mainMenu"
          :links="linksMain"
          :external="external"
          @open-sub-menu="toggleSubMenu"
        />
        <!-- Secondo Livello Expertise -->
        <NavigationMenuLinks
          class="nav-menu__links --sub-menu"
          ref="subMenuExpertise"
          :links="
            showSubMenu === 'expertise'
              ? linksSubmenuExpertise
              : linksSubmenuTechnology
          "
          :external="external"
          @open-sub-menu="toggleSubMenu"
        />
        <client-only>
        <div v-if="showSubMenu === false || showSubMenu === null || showSubMenu === 'main'" ref="menuLang" class="nav-menu__lang-wrap is-text-uppercase">
          <ul>
            <li v-for="locale in locales.filter((code) => code.code !== current.code)" :key="locale.code">
            <a
          @click.prevent.stop="setLocale(locale.code)"
         :href="switchLocalePath(locale.code)"
        >
          <span> {{ locale.code }}</span>
          
        </a></li>
          </ul>
        </div>
         </client-only>
      </div>
    </WidgetOverlayFullScreen>
   
  </nav>
</template>

<script>
export default {
  name: 'NavigationMenu',
}
</script>

<script setup>
import { gsap } from 'gsap'
import { debounce } from '~/assets/js/utils.js'

import queries from '@/graphql/dato/queries/index.js'

import { navMenu } from '@/bucket/navigation.js'

const switchLocalePath = useSwitchLocalePath()
const { locale, locales, setLocale } = useI18n()

const matchMedia = useHelpers().evalMatchMedia('(min-width: 1000px)')
const route = useRoute()

const lightTheme = setLightTheme()

const isLight = ref(false)

const overlay = ref(null)
const mainMenu = ref(null)
const openOverlay = ref(false)
const subMenuExpertise = ref(null)
const subMenuTechnology = ref(null)

const showSubMenu = ref(false)

const menuLang = ref(null)

const tl = ref(null)


const current = computed(() => {
  return locales.value.find(({ code }) => code === locale.value)
})

const props = defineProps({
  external: {
    type: Boolean,
    default: false,
  },
})

const query = queries.navigation.getNavigationSubmenus
const variables = computed(() => ({
  locale: locale.value,
}))
const { data } = await useGraphql(query, variables)
// Link statici e non
const linksMain = navMenu.linksMain
const linksSubmenuExpertise = computed(() => {
  const links = []
  return links.concat(navMenu.linksBack).concat(
    data.value?.allExpertises?.map((category) => {
      return {
        title: category.title,
        route: { name: 'expertise-slug', params: { slug: category.slug } },
      }
    })
    /*.concat(navMenu.linksSubMenuExpertise)*/
  )
})

const linksSubmenuExpertiseDesk = computed(() => {
  const links = []
  return links.concat(
    data.value?.allExpertises?.map((category) => {
      return {
        title: category.title,
        route: { name: 'expertise-slug', params: { slug: category.slug } },
      }
    })
  )
})

const linksSubmenuTechnology = computed(() => {
  const links = []
  return links.concat(navMenu.linksBack).concat(
    data.value?.allTechnologies?.map((category) => {
      return {
        title: category.title,
        route: { name: 'technology-slug', params: { slug: category.slug } },
      }
    })
    /*.concat(navMenu.linksSubMenuTechnologies)*/
  )
})

const linksSubmenuTechnologyDesk = computed(() => {
  const links = []
  return links.concat(
    data.value?.allTechnologies?.map((category) => {
      return {
        title: category.title,
        route: { name: 'technology-slug', params: { slug: category.slug } },
      }
    })
  )
})

watch(
  () => lightTheme.value,
  (lightTheme) => {
    if (lightTheme) {
      isLight.value = true
    } else {
      isLight.value = false
    }
  }
)

// Gestisco Overlay
const toggleOverlay = () => {
  if (tl.value?.isActive()) {
    tl.value.progress(1)
  }
  openOverlay.value = !openOverlay.value
}
watch(
  () => openOverlay.value,
  (openOverlay) => {
    tl.value = openOverlay
      ? animateOverlayIn().duration(1)
      : animateOverlayOut().duration(1.5)
    tl.value.play()
    showSubMenu.value = null
  }
)
watch(
  () => route.path,
  () => {
    if (openOverlay.value) {
      toggleOverlay()
    }
    if (
      route?.name?.includes('contacts') ||
      route?.name?.includes('lets-talk')
    ) {
      if (document.body.classList?.contains('--light-theme')) {
        lightTheme.value = true
      } else {
        document.body.classList?.add('--light-theme')
        lightTheme.value = true
      }
    } else {
      document.body.classList?.remove('--light-theme')
      lightTheme.value = false
    }
  }
)

// Gestisco Submenu
const toggleSubMenu = (action) => {
  showSubMenu.value = action
}

watch(
  () => showSubMenu.value,
  async (showSubMenu, oldShowSubMenu) => {
    const content = document.querySelector(
      '.nav-menu__overlay .overlay-full-screen__content'
    )
    if (showSubMenu === 'main') {
      await subMenuExpertise.value.animateOut().duration(0.6).play()
      content.scrollTo(0, 0)
      await mainMenu.value.animateIn().duration(0.6).play()
      gsap.to(menuLang.value, {autoAlpha: 1, duration: 0.4})
    }
    if (showSubMenu === 'expertise' || showSubMenu === 'technology') {
      await mainMenu.value.animateOut().duration(0.6).play()
      content.scrollTo(0, 0)
      await subMenuExpertise.value.animateIn().duration(0.6).play()
      gsap.to(menuLang.value, {autoAlpha: 0, duration: 0.6})
    }
  }
)

// Animazioni
const animateOverlayIn = () => {
  const tl = gsap.timeline({
    paused: true,
    duration: 1.5,

    onStart: () => {
      document.body.setAttribute('data-menu-overlay-open', true)
    },
    onComplete: () => {
      tl.kill()
    },
  })

  tl.add(overlay.value.animateIn().play(), '<')
  if (mainMenu.value) {
    tl.add(mainMenu.value.animateIn().play())
  }
  if (menuLang.value) {
    tl.fromTo(menuLang.value, {autoAlpha: 0}, {autoAlpha: 1, duration: 0.6})
  }

  return tl
}

const animateOverlayOut = () => {
  const tl = gsap.timeline({
    paused: true,
    duration: 3,
    onComplete: () => {
      document.body.removeAttribute('data-menu-overlay-open', false)

      tl.kill()
    },
  })

  tl.add(overlay.value.animateOut().play(), '<')

  if (mainMenu.value) {
    tl.add(mainMenu.value.animateOut().play())
  }
  if (showSubMenu.value === 'expertise' || showSubMenu.value === 'technology') {
    tl.add(subMenuExpertise.value.animateOut().play())
  }
  if (menuLang.value) {
    tl.to(menuLang.value, {autoAlpha: 0, duration: 0.6})
  }

  return tl
}

onMounted(() => {
  window.addEventListener('resize', debounce(onResize))
  onResize()

  gsap.to(menuLang.value, {autoAlpha: 1, duration: 0.6})

})

const onResize = () => {
  openOverlay.value = false
}
</script>

<style lang="scss">
@import './style.scss';
</style>
