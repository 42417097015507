import gql from 'graphql-tag'

import { projectListingFragment } from '../fragments/projects.js'
import { responsiveImageFragment } from '../fragments/images.js'
import { seoFragment } from '../fragments/seo.js'

export const getTechnologyListingPage = gql`
  ${responsiveImageFragment}
  ${seoFragment}
  query GetTechnologyListingPage($locale: SiteLocale) {
    technologyListing(locale: $locale) {
      title(locale: $locale)
      subtitle(locale: $locale)
      introText(locale: $locale)
      seo(locale: $locale) {
        ...SeoFragment
      }
      ctaType
      contactBannerBlock {
        id
        title
        videoUrl
        ctaLink
        ctaLabel
      }
    }
    allTechnologies(
      locale: $locale
      filter: { hideInNavigation: { eq: "false" } }
    ) {
      title(locale: $locale)
      slug(locale: $locale)
      caption(locale: $locale)
      linkPreviewVideoListing
      imagePreviewListing {
        responsiveImage(
          imgixParams: { auto: format, q: "40", ar: "16:9", fit: crop }
        ) {
          ...ResponsiveImageFragment
        }
      }
    }
  }
`

export const getTechnologyPage = gql`
  ${projectListingFragment}
  ${responsiveImageFragment}
  ${seoFragment}
  query GetTechnologyPage(
    $locale: SiteLocale
    $slug: String
    $siblinsLimit: IntType
  ) {
    technology(locale: $locale, filter: { slug: { eq: $slug } }) {
    _allSlugLocales {
      locale
      value
    }
      seo {
        ...SeoFragment
      }
      tag {
        id
        slug
        _allReferencingProjects(first: $siblinsLimit) {
          ...ProjectListingFragment
        }
      }
      title
      titleFormattato
      subtitle
      introText
      body {
        __typename
        ... on ImageVideoBlockRecord {
          id
          image {
            id
            responsiveImage(
              imgixParams: { ar: "16:9", fit: crop, w: "2000", fm: webp }
            ) {
              ...ResponsiveImageFragment
            }
          }
          linkVideo
          linkVideoPreview
          smallHeight
        }
        ... on AnimatedAccordionRecord {
          id
          title
          accordionsList {
            id
            text
            title
            videoUrl
            image {
              id
              responsiveImage(
                imgixParams: { auto: format, q: "60", fit: crop }
              ) {
                ...ResponsiveImageFragment
              }
            }
          }
        }
        ... on BenefitsBlockRecord {
          id
          title
          texts {
            id
            title
            text
          }
        }
        ... on ServicesBlockRecord {
          id
          title
          texts {
            id
            title
            text
          }
        }
        ... on ChosenBlockRecord {
          id
          title
          showLogosDefault
          imagesList {
            alt
            url
          }
        }
        ... on TwoColumnsGridTextRecord {
          id
          text
          title
          titleAsSimpleText
        }
        ... on CtaBlockRecord {
          id
          ctaLink
          ctaLabel
          title
          subtitle
          videoUrl
          slim
        }
      }
      titleProgettiCorrelati
      bigTitleCorrelati
      ctaType
      contactBannerBlock {
        id
        title
        videoUrl
        ctaLink
        ctaLabel
      }
    }
    clientiSection(locale: $locale) {
      title
      id
      imagesList {
        url
        alt
      }
    }
    allTechnologies(
      locale: $locale
      filter: { hideInNavigation: { eq: "false" } }
    ) {
      id
      title
      slug
      caption
      linkPreviewVideoListing
      imagePreviewListing {
        responsiveImage(
          imgixParams: { auto: format, q: "40", ar: "16:9", fit: crop }
        ) {
          ...ResponsiveImageFragment
        }
      }
    }
  }
`
